<template>
  <section class="ls-page">
    <router-view></router-view>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseRunnerRequests",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  }
};
</script>

<style scoped></style>
